/** fix for ngb-pagination having big padding on ul bottom */
ngb-pagination ul {
  margin-bottom: 0;
}

/** fix so that alert classes can be placed on inner controls*/
.is-invalid {
  background: none !important;
  padding-right: 0 !important;

  .form-control, &.form-control {
    border-color: #dc3545;
  }

  .input-subtle, &.input-subtle {
    border-color: #dc3545;
    padding-right: 1px !important;
  }
}

/** fix for sticky-top th borders disappearing when the table is scrolled.*/
.thead-light th.sticky-top {
  border-top: 0 none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

/** fix for elements that need to appear in front of a .sticky-top element */
.above-sticky-top {
  z-index: 1021;
}

.input-subtle {
  border: #ccc solid 1px;
  padding: 0 1px;
  background: #f8f9fa;
  line-height: 1.2rem;

  &:focus {
    outline: 0;
    border-color: #aaa;
  }
}

label {
  margin-bottom: 0.5rem;
}

.table, table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-group {
  margin-bottom: 1rem;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}